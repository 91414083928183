import * as React from "react";
import {ReactNode} from "react";
import {Route} from "react-router";
import {Redirect} from 'react-router-dom';
import {OAuthRedirect} from "./scenes/unauthorized/OAuthRedirect";
import {SignIn} from "./components/Forms/Sign/SignIn";
import {SignUp} from "./components/Forms/Sign/SignUp";
import {UserType} from "./services/api/ProCvtPaaSClient";
import AccessTokensScene from "./scenes/authorized/AcessTokens/AccessTokensScene";
import {SecurityScanOutlined, UserOutlined } from "@ant-design/icons/lib";
import AccountScene from "./scenes/authorized/Account/AccountScene";

export interface IRouteInfo extends IMenuItemInfo {
    pathForMenuItem?: string;
    exact?: boolean;
    render: (props: any) => React.ReactNode;
    showInMenu: boolean;
    allowForUsers: UserType[];
}

export interface IMenuItemInfo {
    path: string;
    title: string;
    icon?: any;
}

const notAuthorizedRoutePathsInfo: IRouteInfo[] = [
    {
        path: '/sign-up',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <SignUp {...props}/>),
        showInMenu: false,
        allowForUsers: []
    },
    {
        path: '/oauth_redirect',
        exact: true,
        title: '',
        icon: undefined,
        render: (props => <OAuthRedirect {...props}/>),
        showInMenu: true,
        allowForUsers: []
    },
    {
        path: '/oauth_sign_out',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <Redirect to={'/'}/>),
        showInMenu: false,
        allowForUsers: []
    },
    {
        path: '/',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <SignIn {...props}/>),
        showInMenu: false,
        allowForUsers: []
    },
];

const allUserTypes: UserType[] = ["Admin", "User"]

const authorizedRoutePathsInfo: IRouteInfo[] = [
    {
        path: '/account',
        exact: true,
        title: 'Account',
        icon: <UserOutlined />,
        render: (props => <AccountScene {...props}/>),
        showInMenu: true,
        allowForUsers: allUserTypes
    },
    {
        path: '/tokens',
        exact: true,
        title: 'Tokens',
        icon: <SecurityScanOutlined />,
        render: (props => <AccessTokensScene {...props}/>),
        showInMenu: true,
        allowForUsers: allUserTypes
    },
    {
        path: '/',
        exact: false,
        title: '',
        icon: undefined,
        render: (props => <Redirect to={'/tokens'}/>),
        showInMenu: false,
        allowForUsers: []
    },
];

export function getTitleByRoutePathName(routePath: string): string {
    const index = authorizedRoutePathsInfo.findIndex(value => value.path.toLocaleLowerCase().includes(routePath.toLocaleLowerCase()))
    return index !== -1 ? authorizedRoutePathsInfo[index].title : ''
}

export function getNotAuthorizedRoutes(): ReactNode[] {
    return notAuthorizedRoutePathsInfo
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getRoutesForUserType(userType?: UserType): ReactNode[] {
    if (userType === undefined)
        return [];
    return filterByUserType(userType, authorizedRoutePathsInfo)
        .map(info => <Route key={`${info.path}_${info.title}`} path={info.path} exact={info.exact}
                            render={info.render}/>)
}

export function getMenuListForUserType(userType?: UserType): IMenuItemInfo[] {
    if (userType === undefined)
        return [];
    return filterByUserType(userType, authorizedRoutePathsInfo)
        .filter(info => info.showInMenu).map(info => {
            if (info.pathForMenuItem)
                return {...info, path: info.pathForMenuItem}
            else return info
        });
}

function filterByUserType(userType: UserType, routes: IRouteInfo[]) {
    return routes.filter(info => {
        if (info.allowForUsers.length === 0)
            return true;
        else {
            return info.allowForUsers.includes(userType);
        }
    });
}
