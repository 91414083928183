import {ProCvtPaaSClient} from "./ProCvtPaaSClient";
import { Modal } from 'antd';
import axios from 'axios';
import {InitializedStores} from "../../stores/initializeStores";

const qs = require('qs')

const http = axios.create({
    baseURL: ((window as any).$ENV as any).API_URL,
    timeout: 30000,
    paramsSerializer: function(params) {
        return qs.stringify(params, {
            encode: false,
        });
    },
});

http.interceptors.request.use(
    function(config) {
        let token = InitializedStores.authStore.token
        if (!!token) {
            config.headers.common['Authorization'] = 'Bearer ' + token;
        }


        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

/**
 * We can use this variable if will call window.open(variable will clear) only.
 * **/
let allReadyProcessing401 = false

http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log(JSON.stringify(error))
        if (error?.response?.status === 401){
            if(!allReadyProcessing401){
                allReadyProcessing401 = true
                const handle401 = () => {
                    Modal.error({
                        title: 'Authorize error'
                    });
                    InitializedStores.authStore.logOut().finally(() => {
                        // To clear memory variables and show login page.
                        window.open("/", "_self")
                    });
                }
                handle401()
            }
        }
        else {
            Modal.error({
                title: !!error.message ? error.message : 'Unknown error'
            });
        }

        setTimeout(() => {}, 5000);

        return Promise.reject(error);
    }
);

const httpClient = new ProCvtPaaSClient(http)
export default httpClient;
