import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import * as React from "react";
import {Button, Card, Form, Input, Table} from "antd";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {AccessTokenStore, IAccessTokenDto} from "../../../stores/accessToken/accessTokenStore";
import {observable} from "mobx";

const moment = require('moment-timezone')

interface IProps extends RouteComponentProps {
    accessTokenStore?: AccessTokenStore;
}

@inject(InjectNames.accessTokenStore)
@observer
class AccessTokensScene extends React.Component<IProps> {

    @observable tableLoading = false

    componentDidMount = async () => {
        await this.loadMyAccessTokens()
    }

    usePromiseLoading = async (action: Promise<any>) => {
        this.tableLoading = true
        try {
            await action
        } finally {
            this.tableLoading = false
        }
    }
    useActionLoading = async (action: () => Promise<any>) => {
        this.tableLoading = true
        try {
            await action()
        } finally {
            this.tableLoading = false
        }
    }
    loadMyAccessTokens = async () => {
        await this.usePromiseLoading(this.props.accessTokenStore!.loadMyAccessTokens())
    }

    revokeAccessToken = async (record: IAccessTokenDto) => {
        await this.usePromiseLoading(this.props.accessTokenStore!.revokeAccessToken(record.token))
    }

    generateToken = async (values: any) => {
        await this.useActionLoading(async () => {
            await this.props.accessTokenStore!.generateAccessToken(values.tokenDescription)
            this.accessTokenFormRef.resetFields()
        })

    }

    accessTokenFormRef!: FormInstance

    render() {
        return (
            <React.Fragment>
                <Card title={'Generate access token'} style={{marginBottom: 30}}>
                    <Form onFinish={this.generateToken} ref={ref => this.accessTokenFormRef = ref!}>
                        <Form.Item label={'Description'}
                                   name={'tokenDescription'}
                                   rules={[
                                       {required: true, message: 'This field is required.'},
                                       {whitespace: true, message: 'This field is required.'},
                                       {max: 100, message: 'Max length is 100'},
                                   ]}>
                            <Input placeholder={'Enter where you will use token.'}/>
                        </Form.Item>
                        <Button type={'primary'} htmlType={'submit'}>Generate</Button>
                    </Form>
                </Card>
                <Card title={'Access tokens table'}>
                    <Table dataSource={this.props.accessTokenStore!.myAccessTokens}
                           loading={this.tableLoading}
                           columns={[
                               {title: 'Token', dataIndex: 'token'},
                               {title: 'Description', dataIndex: 'tokenDescription'},
                               {
                                   title: 'Creation Date',
                                   dataIndex: 'creationDate',
                                   render: (text, record) => moment(text).format('ll')
                               },
                               {
                                   title: 'Actions', render: (text, record) =>
                                       <Button type={"default"} danger={true} size={'small'}
                                               onClick={() => this.revokeAccessToken(record)}>Revoke</Button>
                               },
                           ]}
                           rowKey={(record) => record.token
                           }/>
                </Card>
            </React.Fragment>
        );
    }
}

export default AccessTokensScene;
