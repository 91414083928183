// @ts-nocheck
// eslint-disable

import {
    AxiosInstance,
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';

export interface RequestHeaders {
    header: string[];
}

export interface IAbpResponse < T > {
    error: any,
    result: T,
    success: boolean,
    targetUrl: string,
    unAuthorizedRequest: boolean
}

export type GetSignedCvtWebsocketResponse = {
    "url" ? : string;
    "partitionKey" ? : string;
    "urlExpirationTime": string;
};;
export type GetSignedCvtWebsocketRequest = {
    "serviceToken" ? : string;
    "targetWebsocket": TargetWebsocket;
    "deviceId" ? : string;
    "clientId" ? : string;
};;
export type TargetWebsocket = "UNKNOWN" | "CLIENT" | "DEVICE";;
export type UserDetails = {
    "id": number;
    "userType": UserType;
    "allowCvtProcessing": boolean;
    "email" ? : string;
    "firstName" ? : string;
    "lastName" ? : string;
    "callbackUrl" ? : string;
};;
export type UserType = "Admin" | "User";;
export type UpdateMyCallbackUrlRequest = {
    "callbackUrl" ? : string;
};;
export type SetUserPermissionsRequest = {
    "userId": number;
    "calcCvtAllowed" ? : boolean;
};;
export type CvtCalcSummaryDto = {
    "id": number;
    "userId": number;
    "userFirstName" ? : string;
    "userLastName" ? : string;
    "processingDate": string;
    "processingDuration": string;
    "traceDuration": string;
    "autoCorrectProfileId": number;
    "confidence": number;
};;
export type BaseDtoOfLong = {
    "id": number;
};;
export type BatchResult = {
    "processingDuration": string;
    "biodata" ? : BatchCvtProcessed;
    "fatigue" ? : BatchFatigueProcessed;
};;
export type BatchCvtProcessed = {
    "summary" ? : CvtSummary;
    "sleepScore" ? : number;
    "heartbeats" ? : Array < BatchCvtPointData >
    ;
};;
export type CvtSummary = {
    "minCvt" ? : number;
    "maxCvt" ? : number;
    "avgCvt" ? : number;
    "cvtStdDev" ? : number;
    "valAvgCvt" ? : number;
    "cvtConfidence" ? : number;
};;
export type BatchCvtPointData = {
    "ms": number;
    "rr": number;
    "cvt" ? : number;
    "hr" ? : number;
};;
export type BatchFatigueProcessed = {
    "startSeq" ? : number;
    "endSeq" ? : number;
    "summary" ? : CvtSummary;
    "heartbeats" ? : Array < BatchCvtPointData >
    ;
};;
export type BatchDeviceRawData = {
    "biodata" ? : BatchDeviceRawDataBody;
};;
export type BatchDeviceRawDataBody = {
    "startTime": string;
    "autoCorrectProfileId": number;
    "maxValCVT" ? : number;
    "heartbeats" ? : Array < BatchDeviceHeartbeat >
    ;
};;
export type BatchDeviceHeartbeat = {
    "ms": number;
    "rrInt": number;
};;
export type AccessTokenDetails = {
    "token" ? : string;
    "tokenDescription" ? : string;
    "creationDate": string;
    "userId": number;
    "userFirstName" ? : string;
    "userLastName" ? : string;
    "allowCvtProcessing": boolean;
};;
export type GenerateAccessTokenRequest = {
    "tokenDescription" ? : string;
};;
export type Response_Home_WarmUp_200 = string;;
export type Response_Home_GetAll_200 = Array < string >
;;
export type Response_Home_Get_200 = string;;
export type Response_CvtCalcSummary_GetMySummaries_200 = Array < CvtCalcSummaryDto >
;;
export type Response_CvtCalcSummary_GetUserSummaries_200 = Array < CvtCalcSummaryDto >
;;
export type Response_AccessToken_GetMyAccessTokens_200 = Array < AccessTokenDetails >
;;

/**
 * 
 * @class ProCvtPaaSClient
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export class ProCvtPaaSClient {

    public constructor(axios: AxiosInstance) {
        this.axios = axios;
        this.delete = this.axios.delete
        this.get = this.axios.get
        this.getUri = this.axios.getUri
        this.head = this.axios.head
        this.options = this.axios.options
        this.patch = this.axios.patch
        this.post = this.axios.post
        this.put = this.axios.put
        this.request = this.axios.request
    }
    private readonly axios: AxiosInstance;
    readonly getUri: (config ? : AxiosRequestConfig) => string;
    readonly request: < T = any, R = AxiosResponse < T >> (config: AxiosRequestConfig) => Promise < R > ;
    readonly get: < T = any, R = AxiosResponse < T >> (url: string, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly delete: < T = any, R = AxiosResponse < T >> (url: string, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly head: < T = any, R = AxiosResponse < T >> (url: string, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly options: < T = any, R = AxiosResponse < T >> (url: string, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly post: < T = any, R = AxiosResponse < T >> (url: string, data ? : any, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly put: < T = any, R = AxiosResponse < T >> (url: string, data ? : any, config ? : AxiosRequestConfig) => Promise < R > ;
    readonly patch: < T = any, R = AxiosResponse < T >> (url: string, data ? : any, config ? : AxiosRequestConfig) => Promise < R > ;

    public Auth_GetSignedCvtWebsocket(params: {
        request: GetSignedCvtWebsocketRequest,
    }): AxiosPromise < GetSignedCvtWebsocketResponse > {
        let body = null;
        let path = "/Auth";
        const query: {} = {};
        body = params['request'];
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Users_GetMyDetials(params: {}): AxiosPromise < UserDetails > {
        let body = null;
        let path = "/Users/GetMyDetials";
        const query: {} = {};
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Users_UpdateMyCallbackUrl(params: {
        request: UpdateMyCallbackUrlRequest,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/Users/UpdateMyCallbackUrl";
        const query: {} = {};
        body = params['request'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Users_SetUserPermissions(params: {
        request: SetUserPermissionsRequest,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/Users/SetUserPermissions";
        const query: {} = {};
        body = params['request'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_WarmUp(params: {}): AxiosPromise < Response_Home_WarmUp_200 > {
        let body = null;
        let path = "/Home/WarmUp";
        const query: {} = {};
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_GetAll(params: {}): AxiosPromise < Response_Home_GetAll_200 > {
        let body = null;
        let path = "/Home";
        const query: {} = {};
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_Post(params: {
        value: string,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/Home";
        const query: {} = {};
        body = params['value'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_Get(params: {
        id: number,
    }): AxiosPromise < Response_Home_Get_200 > {
        let body = null;
        let path = "/Home/{id}";
        const query: {} = {};
        path = path.replace('{id}', `${params['id']}`);
        delete params['id'];
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_Put(params: {
        id: number,
        value: string,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/Home/{id}";
        const query: {} = {};
        path = path.replace('{id}', `${params['id']}`);
        delete params['id'];
        body = params['value'];
        return this.axios({
            method: 'PUT',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public Home_Delete(params: {
        id: number,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/Home/{id}";
        const query: {} = {};
        path = path.replace('{id}', `${params['id']}`);
        delete params['id'];
        return this.axios({
            method: 'DELETE',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public CvtCalcSummary_GetMySummaries(params: {}): AxiosPromise < Response_CvtCalcSummary_GetMySummaries_200 > {
        let body = null;
        let path = "/CvtCalcSummary/GetMySummaries";
        const query: {} = {};
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public CvtCalcSummary_GetUserSummaries(params: {
        userId: number,
    }): AxiosPromise < Response_CvtCalcSummary_GetUserSummaries_200 > {
        let body = null;
        let path = "/CvtCalcSummary/GetUserSummaries";
        const query: {} = {};
        body = params['userId'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public CvtCalc_CalculateBatch(params: {
        deviceRawData: BatchDeviceRawData,
    }): AxiosPromise < BatchResult > {
        let body = null;
        let path = "/CvtCalc/CalculateBatch";
        const query: {} = {};
        body = params['deviceRawData'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public AccessToken_GenerateAccessToken(params: {
        request: GenerateAccessTokenRequest,
    }): AxiosPromise < AccessTokenDetails > {
        let body = null;
        let path = "/AccessToken/GenerateAccessToken";
        const query: {} = {};
        body = params['request'];
        return this.axios({
            method: 'POST',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public AccessToken_RevokeAccessToken(params: {
        accessToken ? : string,
    }): AxiosPromise < void > {
        let body = null;
        let path = "/AccessToken/RevokeAccessToken";
        const query: {} = {};
        query[`accessToken`] = params['accessToken']
        return this.axios({
            method: 'DELETE',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

    public AccessToken_GetMyAccessTokens(params: {}): AxiosPromise < Response_AccessToken_GetMyAccessTokens_200 > {
        let body = null;
        let path = "/AccessToken/GetMyAccessTokens";
        const query: {} = {};
        return this.axios({
            method: 'GET',
            url: path,
            params: query,
            data: (body) ? body : params,
        });

    }

}