import './index.less';
import * as React from 'react';
import {Button, Col, Row, Tag} from 'antd';
import {inject, observer} from "mobx-react";
import {Icon} from '@ant-design/compatible';
import {UserStore} from "../../../stores/user/userStore";
import {AuthStore} from "../../../stores/auth/authStore";
import {InjectNames} from "../../../stores/initializeStores";

export interface IHeaderProps {
    collapsed?: any;
    toggle?: any;
    authStore?: AuthStore;
    userStore?: UserStore;
}


@inject(InjectNames.authStore, InjectNames.userStore)
@observer
export class Header extends React.Component<IHeaderProps> {
    render() {
        return (
            <Row className={'header-container'}>
                <Col style={{textAlign: 'left'}} span={4}>
                    <Icon className="trigger" type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                          onClick={this.props.toggle}/>
                </Col>
                <Col style={{padding: '0px 15px 0px 15px', textAlign: 'right'}} span={20}>
                    <Tag>{this.props.userStore!.userDetails?.email}</Tag>
                    <Button onClick={this.props.authStore!.logOut} size={"small"}>Logout</Button>
                </Col>
            </Row>
        );
    }
}

export default Header;
