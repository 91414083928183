import './AppLayout.less';
import * as React from 'react';
import {ReactNode} from 'react';
import {RouteComponentProps, Switch} from 'react-router-dom';

import {Layout} from 'antd';
import {observer} from "mobx-react";
import {observable, runInAction} from "mobx";
import {IMenuItemInfo} from "../../../RouteSettings";
import SiderMenu from "../../../components/Layout/SiderMenu";
import {SpinOnCenter} from "../../../components/Loading/SpinOnCenter";
import Header from "../../../components/Layout/Header";
import Footer from "../../../components/Layout/Footer";

const {Content} = Layout;

declare interface IProps extends RouteComponentProps {
    menuItems: IMenuItemInfo[];
    routes: ReactNode[];
    loading: boolean;
}

@observer
class AppLayoutScene extends React.Component<IProps> {

    @observable private collapsed = false;

    async componentDidMount() {
        runInAction(() => {
            this.collapsed = document.documentElement.clientWidth < 1100
        })
    }

    toggle = () => {
        this.collapsed = !this.collapsed;
    };

    render() {
        const collapsed = this.collapsed;
        const {history} = this.props;
        return (
            <Layout style={{height: '100vh'}}>
                <SiderMenu layouts={this.props.menuItems} history={history}
                           collapsed={collapsed}/>
                {this.props.loading ? <SpinOnCenter/> :
                    <Layout style={{minWidth: '860px'}}>
                        <Layout.Header style={{background: '#fff', minHeight: 52, padding: 0}}>
                            <Header collapsed={this.collapsed} toggle={this.toggle}/>
                        </Layout.Header>
                        <Layout.Content>
                            <Content style={{margin: 16}}>
                                <Switch>
                                    {this.props.routes}
                                </Switch>
                            </Content>
                        </Layout.Content>
                        <Layout.Footer style={{textAlign: 'center'}}>
                            <Footer/>
                        </Layout.Footer>
                    </Layout>
                }

            </Layout>
        );
    }
}

export default AppLayoutScene;
