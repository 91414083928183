import * as React from 'react';
import {AuthStore} from "../../../stores/auth/authStore";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {Button, Card, Col, Form, Input, Row} from "antd";
import {Auth, Hub} from 'aws-amplify';
import {action, observable} from "mobx";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import {RouteComponentProps} from "react-router-dom";
import {ResetPassword} from "./ResetPassword";
import {VerifyEmail} from "./VerifyEmail";

declare interface IProps extends RouteComponentProps {
    authStore?: AuthStore;
}


@inject(InjectNames.authStore)
@observer
export class SignIn extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
        Hub.listen('auth', async (data) => {
            if (data.payload.event === 'signIn_failure' && data.payload.data.code === 'UserNotConfirmedException') {
                this.needVerifyUser = true
            }
        });
    }

    @observable email = "";
    @observable password = "";
    @observable loading = false;
    @observable needVerifyUser = false;
    @observable error = "";

    @action clearError = () => {
        this.error = ""
    }


    handleUserVerified = () => {
        this.needVerifyUser = false
        this.handleSubmit()
    }

    handleSubmit = async (values?: any) => {
        if (!!values) {
            this.password = values.password
            this.email = values.email
        }
        this.clearError()
        try {
            this.loading = true
            await Auth.signIn({password: this.password, username: this.email})
        } catch (e) {
            this.error = e.message
        } finally {
            this.loading = false
        }
    };

    public render() {
        const span = {xs: 22, sm: 20, md: 12, lg: 8}
        const container = <Row style={{height: '100vh', justifyContent: 'center'}}>
            <Col {...span} style={{alignSelf: 'center'}}>
                <Card title={'Sign In'}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{color: "red"}}>{this.error}</div>
                    </div>

                    <Form onFinish={this.handleSubmit}>
                        <Form.Item name={'email'} rules={[
                            {required: true, message: 'This field is required.'},
                            {type: 'email', message: 'Invalid email'},
                            {whitespace: true, message: 'This field is required.'}
                        ]}>
                            <Input type="email" placeholder={"Enter email"} onChange={() => this.error = ''}/>
                        </Form.Item>
                        <Form.Item name={'password'} rules={[
                            {required: true, message: 'This field is required.'},
                            {whitespace: true, message: 'This field is required.'}
                        ]}>
                            <Input type="password" onChange={() => this.error = ''}
                                   placeholder={"Enter password"}/>
                        </Form.Item>
                        <Row justify={'space-between'}>
                            <Button htmlType={"button"} onClick={async () => {
                                await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google})
                                this.loading = true
                            }}>Google</Button>
                            <Button htmlType={"button"} onClick={async () => {
                                this.props.history.push('/sign-up')
                            }}>Sign Up</Button>
                            <ResetPassword/>
                            <Button htmlType={'submit'} type={"primary"}
                                    loading={this.loading}>Sign In</Button>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <VerifyEmail onConfirm={this.handleUserVerified} confirmUserName={this.email}
                         needVerifyUser={this.needVerifyUser} {...this.props}/>
        </Row>
        return (container)
    }
}
