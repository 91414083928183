import * as React from 'react';
import {Button, Form, Input, Modal, Row} from 'antd';
import {observer} from "mobx-react";
import {observable} from "mobx";
import {Auth} from 'aws-amplify';

interface IProps {
}

const FormItem = Form.Item;

enum ResetPasswordStep {
    EnterEmail,
    ConfirmCode
}

@observer
export class ResetPassword extends React.Component<IProps> {

    @observable step = ResetPasswordStep.EnterEmail;
    @observable loading = false;
    @observable isShowModal = false;
    @observable email: string = "";
    @observable code: string = "";
    @observable newPassword: string = "";
    @observable error: string = "";

    handleEnterEmail = async (values: any) => {
        this.error = ""
        try {
            this.email = values.email
            await Auth.forgotPassword(this.email)
            this.step = ResetPasswordStep.ConfirmCode
        } catch (e) {
            this.error = e.message
        }
    };
    handleConfirmAndNewPassword = async (values: any) => {
        this.error = ""
        try {
            this.code = values.code
            this.newPassword = values.newPassword
            await Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword)
            this.toggleModal()
        } catch (e) {
            this.error = e.message
        }
    };

    toggleModal = () => {
        this.loading = false;
        this.isShowModal = !this.isShowModal;
        this.email = ""
        this.error = ""
        this.step = ResetPasswordStep.EnterEmail
        this.newPassword = ""
        this.code = ""
    }

    public render() {
        const submit = (name: string) => <Row justify={'end'}><Button htmlType={"submit"}
                                                                                    type={"primary"}>{name}</Button></Row>
        const enterEmailForm = <Form onFinish={this.handleEnterEmail}>
            <FormItem name={'email'}>
                <Input placeholder={'Email'}/>
            </FormItem>
            {submit("Send code")}
        </Form>
        const confirmAndNewPasswordForm = <Form onFinish={this.handleConfirmAndNewPassword}>
            <FormItem name={'code'}>
                <Input placeholder={'Code'}/>
            </FormItem>
            <FormItem name={'newPassword'}>
                <Input placeholder={'New password'} type={"password"}/>
            </FormItem>
            {submit("Save")}
        </Form>
        const currentStepForm = this.step === ResetPasswordStep.EnterEmail ? enterEmailForm : confirmAndNewPasswordForm
        return (
            <React.Fragment>
                <Button hidden={this.isShowModal}
                        type={"link"}
                        onClick={this.toggleModal}>Reset password
                </Button>
                <Modal visible={this.isShowModal}
                       title={'Reset password'}
                       footer={false}
                       onCancel={this.toggleModal}
                       okButtonProps={{loading: this.loading}}>
                    <div style={{color: 'red'}}>{this.error}</div>
                    {currentStepForm}
                </Modal>
            </React.Fragment>
        );
    }
}
