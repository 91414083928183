import {action, observable} from 'mobx';
import {RootStore} from "../initializeStores";
import {Auth} from "aws-amplify";
import {CognitoUserSession} from "amazon-cognito-identity-js";
import {ProCvtPaaSClient} from "../../services/api/ProCvtPaaSClient";

export class AuthStore {
    constructor(client: ProCvtPaaSClient, rootStore: RootStore) {
        this.client = client!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtPaaSClient;
    private readonly rootStore: RootStore;

    /**
     *  Authorization
     *  */
    @observable token: string | null = null;
    accessToken: string | null = null;
    idToken: string | null = null;

    @observable isAuthorized: boolean = false;

    @action
    onLogIn = async (session: CognitoUserSession) => {
        let accessToken = session.getAccessToken()
        let idToken = session.getIdToken()

        this.idToken = idToken.getJwtToken()
        this.accessToken = accessToken.getJwtToken()
        this.token = this.idToken
        this.isAuthorized = true;

    }

    @action
    logOut = async () => {
        await Auth.signOut()
    };

    @action
    onLogOut = async () => {
        this.isAuthorized = false;
        await this.rootStore.onLogOut();
        // Should reset token after stores clear data to can call api.
        this.token = null;
        this.accessToken = null;
        this.idToken = null;
    };
}