import * as React from 'react';
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import {RouteComponentProps} from "react-router";
import RealtimeBluetoothDeviceProxy from "../../../components/RealTime/RealtimeBluetoothDeviceProxy";


interface IRouteProps {
}

interface IProps extends RouteComponentProps<IRouteProps> {
}

@inject(InjectNames.userStore, InjectNames.authStore)
@observer
class RealtimeBluetoothDeviceProxyScene extends React.Component<IProps> {

    render() {
        return <RealtimeBluetoothDeviceProxy {...this.props}/>
    }
}

export default RealtimeBluetoothDeviceProxyScene;
