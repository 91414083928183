import {action, observable} from 'mobx';
import {IDisposable, RootStore} from "../initializeStores";
import {ProCvtPaaSClient} from "../../services/api/ProCvtPaaSClient";

export class AccessTokenStore implements IDisposable{
    constructor(client: ProCvtPaaSClient, rootStore: RootStore) {
        this.client = client!;
        this.rootStore = rootStore;
    }

    dispose = async () => {
        this.myAccessTokens = []
    };

    private readonly client: ProCvtPaaSClient;
    private readonly rootStore: RootStore;

    @observable myAccessTokens: IAccessTokenDto [] = [];

    @action
    loadMyAccessTokens = async () => {
        const response = await this.client.AccessToken_GetMyAccessTokens({})
        this.myAccessTokens = response.data.map(token => token as IAccessTokenDto)
    };

    @action
    generateAccessToken = async (tokenDescription: string) => {
        const response = await this.client.AccessToken_GenerateAccessToken({request: {tokenDescription: tokenDescription}})
        this.myAccessTokens = [response.data as IAccessTokenDto, ...this.myAccessTokens]
        return response.data
    };

    @action
    revokeAccessToken = async (token: string) => {
        await this.client.AccessToken_RevokeAccessToken({accessToken: token})
        this.myAccessTokens = this.myAccessTokens.filter(tokenDetails => tokenDetails.token !== token)
    };
}

export interface IAccessTokenDto {
    "token": string;
    "tokenDescription"?: string;
    "creationDate": string;
    "userId": number;
    "userFirstName": string;
    "userLastName": string;
}