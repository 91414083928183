import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {InjectNames} from "../../../stores/initializeStores";
import * as React from "react";
import {Button, Card, Col, Descriptions, Input, Row, Tag} from "antd";
import {UserStore} from "../../../stores/user/userStore";
import {enumHelper} from "../../../services/utils/EnumHelper";
import {action, observable} from "mobx";
import './AccountScene.less'

interface IProps extends RouteComponentProps {
    userStore?: UserStore;
}

@inject(InjectNames.userStore)
@observer
class AccountScene extends React.Component<IProps> {
    @observable loading = false
    @observable callbackUrl?: string = undefined
    @observable callbackUrlUpdating = false

    async componentDidMount() {
        try {
            this.loading = true
            await this.props.userStore!.loadMyDetails()
            this.syncCallbackUrl()
        } finally {
            this.loading = false
        }
    }

    @action
    syncCallbackUrl = () => {
        this.callbackUrl = this.props.userStore!.userDetails!.callbackUrl
    }
    updateCallbackUrl = async () => {
        try {
            this.callbackUrlUpdating = true
            await this.props.userStore!.updateMyCallbackUrl({
                callbackUrl: this.callbackUrl,
            })
            this.syncCallbackUrl()
        } finally {
            this.callbackUrlUpdating = false
        }
    }

    render() {
        return (
            <React.Fragment>
                <Card title={'Account info'} loading={this.loading}>
                    <div>
                        <Descriptions
                            title={<span
                                style={{fontWeight: "normal"}}> {this.props.userStore!.userName?.toUpperCase()}</span>}>
                            <Descriptions.Item
                                label={'Role'}>{enumHelper.getFriendlyName(this.props.userStore!.userType)}
                            </Descriptions.Item>
                            <Descriptions.Item label={'Permissions'}>
                                {this.props.userStore!.allowCvtProcessing
                                    ? <Tag color={'green'}>Cvt processing allowed</Tag>
                                    : <Tag color={'red'}>No cvt processing permission</Tag>
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </div>

                    <Row gutter={[15, 15]} className={'update-callback-url'}>
                        <Col span={24}>
                            <Input size={"small"} disabled={this.callbackUrlUpdating}
                                   type={'url'}
                                   value={this.callbackUrl} onChange={event => this.callbackUrl = event.target.value}
                                   placeholder={'https://my.backend.com/handlePaaSCallbacks'}/>
                        </Col>
                        <Col>
                            <Row gutter={[15, 15]}>
                                <Col><Button size={"small"} onClick={this.syncCallbackUrl}>Restore</Button></Col>
                                <Col><Button size={"small"} type={'primary'} loading={this.callbackUrlUpdating}
                                             onClick={this.updateCallbackUrl}>Save</Button></Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </React.Fragment>
        );
    }
}

export default AccountScene;
