import React from "react";
import {Button, Form, Input, Modal, Row} from "antd";
import FormItem from "antd/es/form/FormItem";
import {computed, observable} from "mobx";
import {Auth} from 'aws-amplify';
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
    confirmUserName: undefined | string;
    needVerifyUser: boolean;
    onConfirm: () => any;
}

@observer
export class VerifyEmail extends React.Component<IProps, any> {
    @observable code = ""
    @observable error = ""

    @computed get visible() {
        return this.props.needVerifyUser
    }

    handleVerifiedCode = async (values: any) => {
        this.code = values.code
        try {
            await Auth.confirmSignUp(this.props.confirmUserName!, this.code)
            this.props.onConfirm()
        } catch (e) {
            this.error = e.message
        }
    }

    submitVerifiedCode = async () => {
        try {
            await Auth.resendSignUp(this.props.confirmUserName!)
        } catch (e) {
            this.error = e.message
        }
    }

    render(): React.ReactElement {
        return (
            <Modal footer={false} closable={false} visible={this.visible}
                   title={`Please check email and enter verification code.`}>
                <Form onFinish={this.handleVerifiedCode}>
                    <div style={{color: 'red'}}>{this.error}</div>
                    <FormItem name={'code'} rules={[
                        {required: true, message: 'This field is required.'},
                        {whitespace: true, message: 'This field is required.'}
                    ]}>
                        <Input placeholder={'Code'} onChange={() => this.error = ''}/>
                    </FormItem>
                    <Row justify={'space-between'}>
                        <Button htmlType={"button"} type={"default"} onClick={this.submitVerifiedCode}>Resend
                            code</Button>
                        <Button htmlType={"submit"} type={"primary"}>Verify code</Button>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
