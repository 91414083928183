import './index.less';
import PBMLogo from '../../../pbm-logo.png';

import * as React from 'react';

import {Avatar, Col, Layout, Menu} from 'antd';
import {IMenuItemInfo} from "../../../RouteSettings";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";


const {Sider} = Layout;

export interface ISiderMenuProps {
    collapsed: boolean;
    history: any;
    layouts: IMenuItemInfo[];
}

@observer
export default class SiderMenu extends React.Component<ISiderMenuProps> {
    render() {
        const {collapsed} = this.props;
        const progress = null
        return (
            <Sider trigger={progress} className={'sidebar'} width={256} collapsible collapsed={collapsed}>
                {collapsed ? (
                    <Col style={{textAlign: 'center', marginTop: 15, marginBottom: 10}}
                         onClick={() => this.props.history.push('/')}>
                        <Avatar shape="square" style={{height: 27, width: 64}} src={PBMLogo}/>
                    </Col>
                ) : (
                    <Col style={{textAlign: 'center', marginTop: 15, marginBottom: 10}}
                         onClick={() => this.props.history.push('/')}>
                        <Avatar shape="square" style={{height: 54, width: 128}} src={PBMLogo}/>
                    </Col>
                )}

                <Menu theme="dark" mode="inline" selectable={false}>
                    {this.props.layouts
                        .map((info: IMenuItemInfo, index: number) => {
                            return (
                                <Menu.Item key={info.path}>
                                    <Link to={info.path}>
                                        {info.icon}
                                        <span>{(info.title)}</span>
                                    </Link>
                                </Menu.Item>
                            );
                        })}
                </Menu>
            </Sider>
        );
    }
};
