import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import * as React from "react";
import {observable} from "mobx";
import {Button, Col, Input, message, Modal, Row} from "antd";
import moment from "moment-timezone";
import {Moment} from "moment-timezone/moment-timezone";
import {base64Helper} from "../../services/utils/Base64Helper";
import {regexHelper} from "../../services/utils/RegexHelper";
import httpClient from "../../services/api/ClientInstance";

const Bluetooth = (navigator as any).bluetooth


interface IProps extends RouteComponentProps {
}


@observer
class RealtimeBluetoothDeviceProxy extends React.Component<IProps> {
    componentWillUnmount() {
        this.disconnectWss()
    }

    @observable latestData = {rr: 0, hr: 0, updatedAt: moment(), ms: 0}
    @observable deviceId = '8C1645929BCD203192829018'
    partitionKey?: string
    @observable serviceToken?: string = undefined
    private readonly actonicaToken = {
        dev: {token: 'ZGXrYzmK8cDWY3JCAptDVxT1-9U06ZQlfJ6uiQ8Eq_wB9JhklDBL7QaBPEsS4zvO', name: 'Public Actonica token: dev env'},
        local: {token: 'MQt6DlsuEQsQ1VsJDX8OiMZXxDTDsmboJGtiYEw-bcyACJbw9HQ4uNmAIyo7EIuX', name: 'Public Actonica token: local env'},
    }
    ws?: WebSocket
    bluetoothDevice?: any = undefined
    startTime?: Moment = undefined
    ms = 0

    get startTimeString() {
        return this.startTime === undefined
            ? undefined
            : this.startTime.format('DD-MM-YYYY HH:mm:ssZ')
    }

    @observable wssConnection = false
    @observable isWssOpened = false


    getDeviceWebsocketOptions = async (deviceId: string, serviceToken: string) => {
        let resp = await httpClient.Auth_GetSignedCvtWebsocket({
            request: {
                targetWebsocket: 'DEVICE',
                deviceId: deviceId,
                serviceToken: serviceToken
            }
        });
        return {
            url: resp.data.url!,
            partitionKey: resp.data.partitionKey!,
            urlExpirationTime: resp.data.urlExpirationTime!
        }
    }


    initWssConnection = async () => {
        await this.connectBle()

        if (regexHelper.isEmptyString(this.deviceId))
            Modal.error({content: 'Device id is required'})
        if (regexHelper.isEmptyString(this.serviceToken))
            Modal.error({content: 'Service token is required'})

        const json = await this.getDeviceWebsocketOptions(this.deviceId, this.serviceToken!)
        this.partitionKey = json.partitionKey
        this.ws = new WebSocket(json.url);

        this.ws!.onerror = evError => {
            message.error('Stream connection error')
            this.onDisconnectedWss()
        }
        this.ws!.onclose = evClose => {
            this.onDisconnectedWss()
        }
        this.ws.onopen = (ev: any) => {
            this.onConnectedWss()
        }
    }
    parseValue = (value: DataView) => {
        // В Chrome 50+ используется DataView.
        // @ts-ignore
        value = value.buffer ? value : new DataView(value);
        let flags = value.getUint8(0);

        // Определяем формат
        let rate16Bits = flags & 0x1;
        let result: { heartRate: number, rrIntervals: number[] } = {heartRate: 0, rrIntervals: []};
        let index = 1;

        // Читаем в зависимости от типа
        if (rate16Bits) {
            result.heartRate = value.getUint16(index, /*littleEndian=*/true);
            index += 2;
        } else {
            result.heartRate = value.getUint8(index);
            index += 1;
        }

        // RR интервалы
        let rrIntervalPresent = flags & 0x10;
        if (rrIntervalPresent) {
            let rrIntervals = [];
            for (; index + 1 < value.byteLength; index += 2) {
                rrIntervals.push(value.getUint16(index, /*littleEndian=*/true));
            }
            result.rrIntervals = rrIntervals;
        }

        return result;
    }
    connectBle = async () => {
        try {
            const options = {
                filters: [{services: ['heart_rate']}],
                acceptAllDevices: false,
            }
            this.bluetoothDevice = await Bluetooth.requestDevice(options);
            this.bluetoothDevice.addEventListener('gattserverdisconnected', this.onDisconnectedBle)
            const server = await this.bluetoothDevice!.gatt!.connect()
            this.startTime = moment()
            this.ms = 0
            const service = await server.getPrimaryService('heart_rate')
            const char = await service.getCharacteristic('heart_rate_measurement')

            char.oncharacteristicvaluechanged = (event: any) => {
                if (!this.isWssOpened) {
                    return;
                }
                const parsedResult = this.parseValue(event.target.value)

                this.latestData = {
                    hr: parsedResult.heartRate,
                    rr: !!parsedResult.rrIntervals.length ? parsedResult.rrIntervals[0] : 0,
                    updatedAt: moment(),
                    ms: this.ms
                }

                const req = JSON.stringify({
                    action: "put_records",
                    Records: [
                        {
                            PartitionKey: this.partitionKey,
                            Data: base64Helper.objTOBase64({
                                biodata: {
                                    autoCorrectProfileId: 10,
                                    startTime: this.startTimeString,
                                    deviceId: this.deviceId,
                                    heartbeats: parsedResult.rrIntervals.map(rr => {
                                        this.ms = this.ms + rr
                                        return {
                                            ms: this.ms,
                                            rrInt: rr
                                        }
                                    })
                                }
                            })
                        }
                    ]
                })

                this.ws?.send(req)
            }

            await char.startNotifications()

        } catch (ex) {
            console.log('Error:', ex.message, ex.code);
            message.error(ex.toString())
            throw ex

        }
    }


    disconnectWss = () => {
        this.ws?.close()
    }
    disconnectBle = () => {
        if (this.bluetoothDevice?.gatt?.connected)
            this.bluetoothDevice.gatt.disconnect()
    }
    onDisconnectedWss = () => {
        this.ws = undefined
        this.isWssOpened = false
        message.error('Connection closed')
        this.disconnectBle()
    }
    onDisconnectedBle = () => {
        this.bluetoothDevice = undefined
        this.disconnectWss()
    }
    onConnectedWss = () => {
        this.isWssOpened = true
    }

    render() {
        const connectWssView = <React.Fragment>
            <Button disabled={this.isWssOpened} loading={this.wssConnection}
                    onClick={this.initWssConnection}>Connect</Button>
            <Button disabled={!this.isWssOpened}
                    onClick={this.disconnectWss}>Disconnect</Button>
        </React.Fragment>


        const deviceControlView =
            <React.Fragment>
                <Row gutter={[15, 15]}>
                    <Col>
                        {connectWssView}
                    </Col>
                </Row>
            </React.Fragment>

        return (
            <React.Fragment>
                <Row gutter={[15, 15]} style={{marginLeft: 30, marginRight: 30}}>
                    <Col span={24}>
                        <label htmlFor={'device_id'}>Device</label>
                        <Input id={'device_id'} value={this.deviceId}
                               onChange={event => this.deviceId = event.target.value}
                               disabled={this.isWssOpened}/></Col>
                    <Col span={24}>
                        <label htmlFor={'access_token'}>Access token</label>
                        <Input id={'access_token'} value={this.serviceToken}
                               onChange={event => this.serviceToken = event.target.value}
                               disabled={this.isWssOpened}/></Col>
                    <Col span={24}>
                        <Row gutter={[30, 15]}>
                            <Col span={6}>
                                <span>{this.actonicaToken.dev.name.toUpperCase()}</span>
                                <Input readOnly={true} value={this.actonicaToken.dev.token}/>
                            </Col>
                            <Col span={6}>
                                <span>{this.actonicaToken.local.name.toUpperCase()}</span>
                                <Input readOnly={true} value={this.actonicaToken.local.token}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col>{deviceControlView}</Col>
                    <Col span={24}>
                        <div>Updated at: {this.latestData.updatedAt.format('hh:mm:ss.ms a')}</div>
                        <div>Duration: {moment.duration(this.latestData.ms).asSeconds()} seconds</div>
                        <div>Heart rate: {this.latestData.hr}</div>
                        <div>RR: {this.latestData.rr}</div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default RealtimeBluetoothDeviceProxy;
