import {AuthStore} from "./auth/authStore";
import httpClient from "../services/api/ClientInstance";
import {UserStore} from "./user/userStore";
import {AccessTokenStore} from "./accessToken/accessTokenStore";

export interface IOnLogOut {
    onLogOut: () => Promise<void>;
}

export interface IDisposable {
    dispose: () => Promise<void>;
}

export class RootStore implements IOnLogOut {
    constructor() {
        this.authStore = new AuthStore(httpClient, this)
        this.userStore = new UserStore(httpClient, this)
        this.accessTokenStore = new AccessTokenStore(httpClient, this)
    }

    authStore!: AuthStore;
    userStore!: UserStore;
    accessTokenStore!: AccessTokenStore;


    onLogOut = async () => {
        await this.clearData();
    };

    clearData = async () => {
        await this.userStore.dispose()
        await this.accessTokenStore.dispose()
    }
}

const rootStore = new RootStore();

export const InitializedStores = {
    authStore: rootStore.authStore,
    userStore: rootStore.userStore,
    accessTokenStore: rootStore.accessTokenStore,
}


export const InjectNames = {
    authStore: 'authStore',
    userStore: 'userStore',
    accessTokenStore: 'accessTokenStore',
}

