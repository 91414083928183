import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {SpinOnCenter} from "../../components/Loading/SpinOnCenter";
import {Modal} from "antd";

interface RouteProps {
    error_description?: string;
    error?: string;
}

interface IPros extends RouteComponentProps<RouteProps> {

}

export class OAuthRedirect extends React.Component<IPros> {

    componentDidMount() {
        const error = this.props.location.search.match(/\?error_description=.*\+&/);
        if (!!error?.length)
            this.displayErrorAndToMain(error[0].replace('?error_description=', '').replace('&', '').split('+').join(' '))
    }

    displayErrorAndToMain = (error:string) => {
        Modal.error({title: error, onOk:() => this.props.history.push('/')})
    };



    render() {
        return <SpinOnCenter></SpinOnCenter>;
    }
}
