class Base64Helper {
    fileToBase64(file: Blob, onConverted: (base64: string) => void) {
        let reader = new FileReader();
        reader.onload = (async (ev) => {
            let fileBase64: string = reader.result as string;
            fileBase64 = fileBase64.replace(/.*,/, '');
            onConverted(fileBase64)
        });
        reader.readAsDataURL(file);
    }

    base64ToImageUrl(base64: string | undefined) {
        if (!base64)
            return undefined;
        return `data:image;base64,${base64}`
    }
    base64ToObj(base64: string | undefined) {
        if (!base64)
            return undefined;
        return JSON.parse(atob(base64))
    }
    objTOBase64(obj: any) {
        if (!obj)
            return undefined;
        return btoa(JSON.stringify(obj))
    }

}

export const base64Helper = new Base64Helper();