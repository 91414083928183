class RegexHelper {
    isEmptyString(str: string | undefined | null) {
        if (str === null || str === undefined)
            return true;
        else if (str?.length === 0)
            return true;
        else {
            const onlyWhitespace = !str.replace(/\s/g, '').length;
            return onlyWhitespace;
        }

    }
}

export const regexHelper = new RegexHelper();
