import './index.less';
import * as React from 'react';
import {observer} from "mobx-react";
import {RouteComponentProps, Switch} from "react-router-dom";

declare interface IProps extends RouteComponentProps {
    routes: any[];
}

@observer
export default class UnauthorizedContainer extends React.Component<IProps> {
    public render() {
        return (
            <Switch>
                {this.props.routes}
            </Switch>
        );
    }
}

