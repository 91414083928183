import * as React from 'react';
import { Col } from 'antd';
import './index.less';

const Footer = () => {
  return (
      <Col className={"footer"}>
      PBM PaaS © 2021
      </Col>
  );
};
export default Footer;
