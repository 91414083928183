import {action, computed, observable} from 'mobx';
import {IDisposable, RootStore} from "../initializeStores";
import {ProCvtPaaSClient, UserType} from "../../services/api/ProCvtPaaSClient";

export class UserStore implements IDisposable {
    constructor(client: ProCvtPaaSClient, rootStore: RootStore) {
        this.client = client!;
        this.rootStore = rootStore;
    }

    private readonly client: ProCvtPaaSClient;
    private readonly rootStore: RootStore;

    @observable userDetails?: IUserDetailsDto;

    @computed get userName() {
        return `${this.userDetails?.firstName} ${this.userDetails?.lastName}`
    }

    @computed get userType() {
        return this.userDetails?.userType
    }
    @computed get allowCvtProcessing() {
        return this.userDetails?.allowCvtProcessing
    }

    @action
    loadMyDetails = async () => {
        const response  = (await this.client.Users_GetMyDetials({}))!.data;
        this.userDetails = response as IUserDetailsDto;
    };

    @action
    updateMyCallbackUrl = async (input: IUpdateMyCallbackInput) => {
        (await this.client.Users_UpdateMyCallbackUrl({request: input}));
        this.userDetails!.callbackUrl = input.callbackUrl
    };

    @action
    dispose = async () => {
        this.userDetails = undefined
    };
}

export interface IDictionary<T> {
    [key: string]: T;
}

export interface IUserDetailsDto {
    "id": number;
    "userType": UserType;
    "allowCvtProcessing": boolean;
    "email": string;
    "firstName": string;
    "lastName": string;
    "callbackUrl"?: string;
}

export interface IUpdateMyCallbackInput {
    "callbackUrl"?: string;
}
